/* eslint-disable max-statements */
import moment from 'moment';
import { ControlContainer } from '@angular/forms';
import type { FormGroup, UntypedFormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import type { SubmittableFormGroup } from '../..';
import type { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import type { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import type { UserPrefs } from 'src/app/user/user-preferences/shared/user-prefs';

/**
 * Component for displaying date/time range filters
 */
@Component({
  selector: 'mtx-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {

  /** Formgroup passed from element */
  @Input() public tgtFormGroup?: SubmittableFormGroup | FormGroup;

  @Input() public tgtFormControlNameDate = 'date';

  @Input() public tgtFormControlNameTime = 'time';

  public userPrefsLoadingRequest$?: Observable<UserPrefs>;

  /** Bindings for ui bootstrap date/time pickers */
  public dateTimeFilters: { date: NgbDateStruct | null; time: NgbTimeStruct | null } =
    {
      date: null,
      time: null,
    };

  public dispValue = '';

  public useMeridian = false;

  private fcd?: UntypedFormControl;

  private fct?: UntypedFormControl;

  private currentSettings: UserPrefs | undefined = undefined;

  public constructor(
    @Inject(ControlContainer) private readonly controlContainer: ControlContainer,
    @Inject(AuthService) private readonly authService: AuthService,
  ) { }

  /**
   * Sets initial filters
   */
  public ngOnInit(): void {
    // todo: could this occur later?
    this.fcd = this.controlContainer.control?.get(this.tgtFormControlNameDate) as UntypedFormControl;
    this.fct = this.controlContainer.control?.get(this.tgtFormControlNameTime) as UntypedFormControl;
    this.getSettings();
    this.setDateTime();

    // todo: should have been in ngOnChanges incase of rebinding
    this.tgtFormGroup?.valueChanges.pipe(first()).subscribe(() => {
      this.setDateTime();
    });
  }

  // update formcontrol datetimes
  // eslint-disable-next-line max-statements
  public updateDateTime(): void {
    const check = moment([
      this.dateTimeFilters.date ? this.dateTimeFilters.date.year : '',
      this.dateTimeFilters.date ? this.dateTimeFilters.date.month - 1 : '',
      this.dateTimeFilters.date ? this.dateTimeFilters.date.day : '',
      this.dateTimeFilters.time ? this.dateTimeFilters.time.hour : '',
      this.dateTimeFilters.time ? this.dateTimeFilters.time.minute : '',
      this.dateTimeFilters.time ? this.dateTimeFilters.time.second : '',
    ]);

    let dateTime = '';
    let date = '';
    let time = '';

    const checkIsValid = check.isValid();
    if (this.tgtFormControlNameDate === this.tgtFormControlNameTime) {
      // one control
      if (checkIsValid) {
        dateTime = check.format('YYYY-MM-DDTHH:mm:ss');
        this.dispValue = dateTime;
        this.fcd?.setValue(dateTime);
      } else {
        this.fcd?.setErrors({ invalidDate: 'invalid date' },
          { emitEvent: true });
        this.fcd?.setValue(null);
      }
    } else {
      // two controls
      // eslint-disable-next-line no-lonely-if
      if (checkIsValid) {
        dateTime = check.format('YYYY-MM-DDTHH:mm:ss');
        this.dispValue = dateTime;
        date = check.format('YYYY-MM-DD');
        time = check.format('HH:mm:ss');
        this.fcd?.setValue(date);
        this.fct?.setValue(time);
      } else {
        this.fcd?.setErrors({ invalidDate: 'invalid date' },
          { emitEvent: true });
        this.fct?.setErrors({ invalidTime: 'invalid time' },
          { emitEvent: true });
        this.fcd?.setValue(null);
        this.fct?.setValue(null);
      }
    }
    this.fcd?.markAsTouched({ onlySelf: true });
    this.fct?.markAsTouched({ onlySelf: true });
    this.fcd?.updateValueAndValidity();
    this.fct?.updateValueAndValidity();
  }

  // update internal component date/times
  private setDateTime(): void {
    if (this.fcd?.value === null) {
      this.dispValue = '';
      this.dateTimeFilters.date = null;
      this.dateTimeFilters.time = null;
      return;
    }

    let mom = moment(`${this.fcd?.value}T${this.fct?.value}`, 'YYYY-MM-DDTHH:mm:ss');

    // date + time formcontrols are the same
    if (this.tgtFormControlNameDate === this.tgtFormControlNameTime) {
      mom = moment(this.fcd?.value as string, 'YYYY-MM-DDTHH:mm:ss');
    }

    if (mom.isValid()) {
      this.dateTimeFilters.date = {
        year: mom.year(),
        day: mom.date(),
        month: mom.month() + 1,
      };
      this.dateTimeFilters.time = {
        hour: mom.hour(),
        minute: mom.minute(),
        second: mom.second(),
      };

      this.dispValue = mom.format('YYYY-MM-DDTHH:mm:ss');
      this.checkIf24HourClock();
    } else {
      this.dispValue = '';
      this.dateTimeFilters.date = null;
      this.dateTimeFilters.time = null;
    }
  }

  private checkIf24HourClock(): void {
    if (this.currentSettings?.use_24_hour_clock === true) {
      this.useMeridian = false;
    } else {
      this.useMeridian = true;
    }
  }

  private getSettings(): void {
    this.userPrefsLoadingRequest$ = this.authService.getCurrentUserPrefs();
    this.userPrefsLoadingRequest$.subscribe({
      next: (res: UserPrefs) => {
        this.userPrefsLoadingRequest$ = undefined;
        this.currentSettings = res;
      },
      error: () => {
        this.userPrefsLoadingRequest$ = undefined;
      },
    });
  }

}
