<div class="container-fluid mb-3 p-0">


	<!-- HEADER -->
	<nav id="header" class="container-fluid">
		<div class="row h-100">
			<div class="col-12">

				<div class="container-fluid h-100">
					<div class="row h-100">

						<div class="col-6 my-auto">

							<span class="align-middle d-none d-lg-block">
								<a href="https://www.angeltrax.com/contact" target="_blank" id="show-contact"
									[translate]="'shared.contact-us'"></a> |
								<span>{{currentOrgName}}</span>
							</span>

							<img src="assets/images/mtx-logo-sm.png" class="d-block d-lg-none" />

						</div>
						<div class="col-6 my-auto text-end">
							<div ngbDropdown [autoClose]="true" class="d-inline-block pe-4">
								<a *ngIf="(moduleLinks$ |async) ?? [].length > 0"
									style="cursor:pointer" id="module-menu" ngbDropdownToggle>
									<i class="fa fa-th"></i>
								</a>
								<ul id="module-menu-dd" ngbDropdownMenu aria-labelledby="module-menu"
									class="dropdown-menu-center">
									<div class="row m-0">
										<li class="col-sm-4 col-6 p-2" *ngFor="let moduleLink of (moduleLinks$ | async)?.accessableLinks">
											<a class="user-menu-link" routerLink="/{{moduleLink.path}}">
												<div class="d-flex justify-content-center">
													<i style="font-size: 50px; color: #ffffff; "
														class="{{moduleLink.data?.icon}} module-icon mb-2"></i>
												</div>
												<div class="d-flex justify-content-center"
													style="color: #ffffff; text-align: center; "
													[translate]="moduleLink.data?.title"></div>

											</a>
										</li>
                    <li class="col-sm-4 col-6 p-2" *ngFor="let moduleLink of (moduleLinks$ | async)?.disabledLinks">
											<div class="user-menu-link">
												<div class="d-flex justify-content-center">
													<i style="font-size: 50px; color: #9c9c9c; cursor: not-allowed"
														class="{{moduleLink.data?.icon}} module-icon mb-2"></i>
												</div>
												<div class="d-flex justify-content-center"
													style="color: #9c9c9c; text-align: center; "
													[translate]="moduleLink.data?.title"></div>

											</div>
										</li>
										<div class="col-12 d-flex justify-content-center">
											<img style="opacity: 20%;" class="col-7"
												src="assets/images/mtx-logo-sm.png">
										</div>
									</div>
								</ul>
							</div>

							<div ngbDropdown class="d-inline-block" [placement]="'bottom-right'">
								<a style="cursor:pointer" id="user-menu" ngbDropdownToggle><i class="fa fa-cog"></i></a>
								<div ngbDropdownMenu aria-labelledby="user-menu">
									<a class="user-menu-link" routerLink="/users/preferences">
										<li class="dropdown-item">
											<i class="fa fa-user"
												style="position: relative; left: 2px;"></i>&nbsp;&nbsp;
											<span style="position: relative; left: 4px;"
												[translate]="'shared.preferences'"></span>
										</li>
									</a>

									<a class="user-menu-link" (click)="openChangeOrgModal();">
										<li class="dropdown-item">
											<i class="mtx-icon mtx-icon-setup"></i>&nbsp;&nbsp;
											<span [translate]="'shared.change-orgs'"></span>
										</li>
									</a>

									<a class="user-menu-link" [mtxIfUserHasPermission]="['avl_view_events']" (click)="openUserNotifsModal();">
										<li class="dropdown-item">
											<i class="fa fa-bell"></i>&nbsp;&nbsp;
											<span [translate]="'shared.notifications'"></span>
										</li>
									</a>

									<a *ngIf="issuedByAcctsAT" class="user-menu-link" href="https://accounts.angeltrax.com/realms/master/account/#/personal-info" target="_blank">
										<li class="dropdown-item">
											<i class="far fa-address-card"></i>&nbsp;&nbsp;
											<span [translate]="'shared.profile'"></span>
										</li>
									</a>

									<a class="user-menu-link" (click)="logout()">
										<li class="dropdown-item">
											<i class="fa fa-power-off" id="logout-link"></i>&nbsp;&nbsp;
											<span [translate]="'shared.logout'"></span>
										</li>
									</a>
								</div>
							</div>

						</div>

					</div>

				</div>
			</div>
		</div>
	</nav>

	<!-- END: HEADER -->


	<div class="container-fluid p-0" id="primary-nav">
		<nav class="navbar navbar-expand-lg py-0">
			<div class="col-lg-8 offset-lg-2" *ngIf="isLoggedIn$ | async as isLoggedIn">
				<button class="navbar-toggler" type="button" data-toggle="collapse"
					data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
					aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
					<span class="navbar-toggler-icon"></span>
				</button>


				<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
					<ul class="navbar-nav d-flex justify-content-around w-100">
						<li *ngFor="let link of links$ | async" class="text-center">
							<div id="{{link.data?.id}}Dropdown" class="nav-item"
								*ngIf="link.children && !hasNavChildLinks(link); else noDropDownBlock" style="cursor: pointer;"
								ngbDropdown on-mouseleave="toggleDropdown($event, link.data?.id + 'Dropdown');"
								on-mouseover="toggleDropdown($event, link.data?.id + 'Dropdown');">
								<a
									class="nav-link"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								 	[ngClass]="{ 'is-active': anyChildIsActive(link)}"
									ngbDropdownToggle>
										<i class="{{link.data?.icon}} pe-1"></i>
										<span>
											{{link.data?.title | translate}}
                      <span *ngIf="link.data?.attentionService">
                        <i class="fa fa-flag needs-attention" *ngIf="(showAttentionIcon(link) | async) === true"></i>
                      </span>
										</span>

										<span class="ms-2"><i class="fas fa-angle-right"></i></span>
								</a>
								<div aria-labelledby="navbarDropdown" ngbDropdownMenu class="submenu-container">
										<div *ngFor="let childLink of link.children" >

										<a *ngIf="childLink.data?.showInNav"
											class="dropdown-item text-center"
											[mtxIfUserHasPermission]="childLink.data?.permissions" routerLinkActive
											#rla="routerLinkActive"
											[ngClass]="{ 'is-active': rla.isActive }"
											[routerLinkActiveOptions]= "{exact: false}"
											[queryParams]="applyQueryParams(childLink)"
											routerLink="/{{moduleName}}/{{link.path}}/{{childLink.path}}">

											<i class="{{childLink.data?.icon}} pe-1"></i>
											<span>
												{{childLink.data?.title | translate}}
												<i class="fa fa-flag needs-attention" *ngIf="(showAttentionIcon(childLink) | async) === true"></i>
											</span>
										</a>

								</div>
								</div>
							</div>
							<ng-template #noDropDownBlock>
								<a
									class="nav-item nav-link text-center"
									routerLink="/{{moduleName}}/{{link.path}}"
									routerLinkActive #rla="routerLinkActive"
									[ngClass]="{ 'is-active': rla.isActive || anyChildIsActive(link) }"
                  					[queryParams]="applyQueryParams(link)"
									[routerLinkActiveOptions]="{exact: false}">

									<i class="{{link.data?.icon}} pe-1"></i>
									<span>
										{{ (link.data?.title | translate) ?? '?'}}
                    <span *ngIf="link.data?.attentionService">
                      <i class="fa fa-flag needs-attention" *ngIf="(showAttentionIcon(link) | async) === true; else iconPH2"></i>
                      <ng-template #iconPH2>
                        <span style="padding-right: 17px;"></span>
                      </ng-template>
                    </span>
									</span>

								</a>
							</ng-template>
						</li>

					</ul>
				</div>
			</div>


		</nav>
	</div>
</div>
